import styled from 'styled-components';

export const ButtonStyle = styled.a`
  display: block;
  position: fixed;
  z-index: 99;
  bottom: 0;
  left: 0;
  padding: 18px 25px 12px 25px;
  font-family: 'Adelle-Bold', arial, sans-serif;
  font-size: 20px;
  line-height: 20px;
  text-transform: uppercase;
  color: #fff;
  background: #55aa4c;
  border-top-right-radius: 10px;
  transition: all 0.25s ease-out;
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 16px;
  }
  :hover {
    padding-bottom: 18px;
    color: #fff;
    background: #55aa4c;
  }
`;
export const ParaText = styled.p`
  margin-bottom: 40px;
  @media (max-width: 1399px) {
    margin-bottom: 30px;
  }
  i {
    font-style: italic;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 24px;
    @media (max-width: 1399px) {
      font-size: 15px;
      line-height: 22px;
    }
    @media (max-width: 767px) {
      font-size: 13px;
      line-height: 20px;
    }
  }
`;
